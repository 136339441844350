<template>
  <v-row>
    <v-col
      v-for="(val, i) in idFields"
      :key="i"
      cols="4"
    >
      <v-text-field
        v-model="value[val.toLowerCase()]"
        :label="val"
        :readonly="!isInsert && ['ISIN', 'WKN'].includes(val)"
        :rules="idFieldRules[i]"
        @input="emitChange"
      />
    </v-col>
    <v-col
      cols="4"
    >
      <v-select
        v-model="value.vwd"
        :items="vwdItems"
        label="VWD"
        @input="emitChange"
      ></v-select>
    </v-col>
  </v-row>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    name: "IdFields",
    props: {
      value: {
        type: Object,
        required: true,
      },
      isInsert: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {
        idFields: ['ISIN', 'WKN', 'Ariva', 'Figi', 'Opiand', 'RIC'],
        idFieldRules: [
          [
            v => v.length === 12 || this.$t('validation.containXCharacters', ['ISIN', '12']),
            v => !!v.match(/^[A-Z]{2}[A-Z0-9]*[0-9]$/) || this.$t('validation.invalidFormat'),
          ],
          [
            v => v.length === 6 || this.$t('validation.containXCharacters', ['WKN', '6']),
            v => !!v.match(/^[A-Z0-9]*$/) || this.$t('validation.invalidFormat'),
          ],
          [/* ariva */],
          [/* figi */],
          [/* opiand */],
          [/* ric */],
        ],
        vwdItems: [],
      }
    },
    methods: {
      ...mapActions(['fetchVWDCodes']),

      onRefresh() {
        this.fetchVWDCodes(this.value.wkn).then(response => {
          if (response.data != null && response.data.data != null && response.data.data instanceof Array) {
            this.$set(this, 'vwdItems', response.data.data)
          }
        })
      },
      emitChange() {
        this.$emit('change')
      },
    },
  }
</script>

<style scoped>

</style>
