<template>
  <v-card>
    <v-card-title>
      <h2>{{ $t('actions.createX', [$t('stocks.stock')]) }}</h2>

      <CloseButton
        @click="cancel"
      ></CloseButton>
    </v-card-title>
    <v-divider class="mt-0"></v-divider>
    <v-card-text>
      <stock-editor
        v-model="stock"
        :is-insert="true"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        large
        color="primary"
        @click="create"
      >
        <v-icon
          left
        >
          check
        </v-icon>
        {{ $t('actions.create') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import StockEditor from "./StockEditor"
  import { mapActions } from 'vuex'
  import CloseButton from "@/components/stocks/CloseButton"

  export default {
    name: "CreateStockDialog",
    components: {CloseButton, StockEditor},
    data() {
      return {
        stock: {
          dq: "",
          ids: {isin: "", wkn: "", vwd: null, opiand: null, ric: null, ariva: null, figi: null},
          name: "",
          common: {currency: null, country: null, industryField: null, expirationDate: null},
        },
      }
    },
    methods: {
      ...mapActions(['fetchSingleStock', 'addStock', 'activateStock']),

      cancel() {
        this.resetInputs()
        this.$emit('cancel')
      },
      create() {
        if (!this.stock.ids.isin.match(/^[A-Z]{2}[A-Z0-9]{9}[0-9]$/) || !this.stock.ids.wkn.match(/^[A-Z0-9]{6}$/)
          || !this.stock.name) {
          this.$store.commit('SHOW_SNACKBAR', {text: 'Invalid inputs! Make sure Name, ISIN and WKN are valid.'})
          return
        }

        this.fetchSingleStock(this.stock.dq).then(() => {
          this.$store.commit('SHOW_SNACKBAR', {text: 'This product already exists, please update it instead!'})
        }).catch(error => {
          if (error.response.status === 404 || error.response.status === 500) {
            this.addStock(this.stock).then(() => {
              this.activateStock(this.stock.dq).then(() => {
                this.$store.commit('SHOW_SNACKBAR', {text: `Succesfully inserted and activated product with DQ ${this.stock.dq}`})
                this.$emit('onStockInserted', {dq: this.stock.dq, name: this.stock.name})
                this.cancel()
              })
            }).catch(() => {
              this.$store.commit('SHOW_SNACKBAR', {text: 'Failed to insert product. Please try again later!'})
            })

          } else {
            this.$store.commit('SHOW_SNACKBAR', {text: 'Failed to insert product. Please try again later!'})
          }
        })
      },
      resetInputs() {
        this.stock = {
          dq: "",
          ids: {isin: "", wkn: "", vwd: null, opiand: null, ric: null, ariva: null, figi: null},
          name: "",
          common: {currency: null, country: null, industryField: null, expirationDate: null},
        }
      },
    },
  }
</script>

