<template>
  <v-btn
    fab
    right
    absolute
    elevation="0"
    small
    plain
    @click="$emit('click')"
  >
    <v-icon>close</v-icon>
  </v-btn>
</template>

<script>
  export default {
    name: 'CloseButton',
  }
</script>
