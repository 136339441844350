<template>
  <v-card>
    <v-card-title>
      {{ $t('search.results') }}
      <span
        v-if="queryResults.length > 0 && !searchLoading"
        class="pl-2"
      >
        {{ $vuetify.lang.t('$vuetify.dataFooter.pageText', searchResultStart, searchResultEnd, searchCount) }}
      </span>
    </v-card-title>
    <v-card-text v-if="searchLoading">
      <strong>{{ $t('notification.loading') }}</strong>
    </v-card-text>
    <v-card-text v-else-if="queryResults.length === 0 && beforeFirstSearch">
      <strong>{{ $t('stocks.useSearchBar') }}</strong>
    </v-card-text>
    <v-card-text v-else-if="queryResults.length === 0">
      <strong> {{ $t('search.noResultsText', [$t('stocks.stocks')]) }} </strong>
    </v-card-text>
    <v-card-text v-else>
      <v-divider />
      <v-pagination
        v-model="searchPage"
        :length="searchMaxPages"
        :total-visible="5"
      ></v-pagination>
      <v-divider />
      <v-list dense>
        <v-list-item-group
          ref="stocks"
          color="primary"
          @change="onStockChange"
        >
          <v-list-item
            v-for="(stock, i) in queryResults"
            :key="i"
          >
            <v-list-item-icon>
              <v-icon>trending_up</v-icon>
            </v-list-item-icon>
            <v-list-item-content @click="onStockSelection(stock, i)">
              {{ stock.name }} ({{ stock.dq }})
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    name: 'StockSearch',
    props: {
      searchQuery: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        searchLoading: false,
        searchPage: 1,
        searchCount: 0,
        searchMaxPages: 1,
        searchResultsPerPage: 10,

        queryResults: [],

        selectedStockPosition: undefined,
        selectedStockDQ: null,
        beforeFirstSearch: true,
      }
    },
    computed: {
      searchResultStart() {
        return (this.searchPage - 1) * this.searchResultsPerPage + 1
      },
      searchResultEnd() {
        return Math.min(this.searchCount, this.searchPage * this.searchResultsPerPage)
      },
    },
    watch: {
      searchPage() {
        this.fetchStocksByQueryFilter({
          search: this.searchQuery,
          page: this.searchPage,
          limit: this.searchResultsPerPage,
        }).then(response => {
          if (response.data != null && response.data['data'] instanceof Array) {
            this.queryResults = response.data.data
            let pos = this.queryResults.findIndex(index => index.dq === this.selectedStockDQ)
            this.selectedStockPosition = pos >= 0 ? pos : undefined

            if (this.queryResults.length) {
              this.$nextTick(() => this.$set(this.$refs.stocks, 'internalLazyValue', this.selectedIndexPosition))
            }
          }
        })
      },
    },
    methods: {
      ...mapActions(['fetchStocksByQueryFilter', 'countStocksByQueryFilter']),

      getSearchResults(prevDQ) {
        if (this.searchLoading) {
          this.$store.commit('SHOW_SNACKBAR', {text: 'Please wait for the results of your previous query!'})
        } else if (this.searchQuery.length < 2) {
          this.$store.commit('SHOW_SNACKBAR', {text: 'Please provide more details (>=2 chars)'})
        } else {
          this.beforeFirstSearch = false
          this.searchLoading = true
          this.searchPage = 1

          this.countStocksByQueryFilter(this.searchQuery).then(response => {
            if (response.data != null && !isNaN(parseInt(response.data))) {
              this.searchCount = parseInt(response.data)
              this.searchMaxPages = Math.max(1, Math.ceil(this.searchCount / this.searchResultsPerPage))
            }
          })

          this.fetchStocksByQueryFilter({
            search: this.searchQuery,
            page: this.searchPage,
            limit: this.searchResultsPerPage,
          }).then(response => {
            this.searchLoading = false
            if (response.data != null && response.data['data'] instanceof Array) {
              this.queryResults = response.data['data']
              let position = prevDQ ? this.queryResults.findIndex(stock => stock.dq === prevDQ) : undefined
              let stock = (!isNaN(position) && position >= 0) ? this.queryResults[position] : null

              this.onStockSelection(stock, position)
              if (this.queryResults.length) {
                this.$nextTick(() => this.$set(this.$refs.stocks, 'internalLazyValue', position))
              }
            }
          })
        }
      },

      onStockSelection(stock, position) {
        this.selectedStockPosition = position
        this.selectedStockDQ = stock != null ? stock.dq : null
        this.$emit('onStockSelection', stock)
      },

      onStockChange(position) {
        if (position === undefined) this.$set(this.$refs.stocks, 'internalLazyValue', this.selectedStockPosition)
      },
    },
  }
</script>

<style scoped>

</style>
