<template>
  <v-container
    fluid
    grid-list-xl
  >
    <toolbar
      title="Aktien"
      :search.sync="search"
      @search="getSearchResults()"
    >
      <template
        v-slot:actions
      >
        <v-col
          class="shrink"
        >
          <v-btn
            large
            @click="createStockDialog = true"
          >
            <v-icon
              :left="$vuetify.breakpoint.lgAndUp"
              color="accent"
            >
              add_circle_outline
            </v-icon>
            <span class="hidden-md-and-down">{{ $t('actions.createX', [$t('stocks.stock')]) }}</span>
          </v-btn>
        </v-col>
        <template
          v-if="selectedStock"
        >
          <v-col
            class="shrink"
          >
            <v-btn
              large
              color="primary"
              @click="updateStock"
            >
              <v-icon :left="$vuetify.breakpoint.lgAndUp">
                save
              </v-icon>
              <span class="hidden-md-and-down">{{ $t('actions.save') }}</span>
            </v-btn>
          </v-col>

          <v-col
            class="shrink"
          >
            <v-btn
              large
              @click="resetChanges"
            >
              <v-icon
                :left="$vuetify.breakpoint.lgAndUp"
                color="accent"
              >
                undo
              </v-icon>
              <span class="hidden-md-and-down">{{ $t('actions.reset') }}</span>
            </v-btn>
          </v-col>
          <v-col
            class="shrink"
          >
            <v-btn
              large
              @click="reactivate"
            >
              <v-icon
                :left="$vuetify.breakpoint.lgAndUp"

                color="accent"
              >
                sync
              </v-icon>
              <span class="hidden-md-and-down">Re-Derive</span>
            </v-btn>
          </v-col>
          <v-col
            class="shrink"
          >
            <v-btn
              large
              color="error"
              @click="confirmDeleteStockDialog = true"
            >
              <v-icon
                :left="$vuetify.breakpoint.lgAndUp"
              >
                delete_forever
              </v-icon>
              <span class="hidden-md-and-down">{{ $t('actions.delete') }}</span>
            </v-btn>
          </v-col>
        </template>
      </template>
    </toolbar>

    <v-row
      row
      wrap
    >
      <v-col
        xs="12"
        sm="6"
        md="4"
        lg="3"
        xl="3"
      >
        <stock-search
          ref="search"
          :search-query="search"
          @onStockSelection="onStockSelection"
        />
      </v-col>
      <v-col
        v-if="selectedStock"
        xs="12"
        sm="6"
        md="8"
        lg="7"
        xl="6"
      >
        <v-card>
          <v-card-text>
            <StockEditor
              ref="editor"
              v-model="newSelectedStock"
              :is-insert="false"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="createStockDialog"
      width="800"
      persistent
    >
      <create-stock-dialog
        @cancel="createStockDialog = false"
        @onStockInserted="onStockInserted"
      />
    </v-dialog>
    <v-dialog
      v-if="selectedStock"
      v-model="confirmDeleteStockDialog"
      width="500"
      persistent
    >
      <confirm-delete-stock-dialog
        v-model="selectedStock.dq"
        @cancel="confirmDeleteStockDialog = false"
        @confirmDelete="confirmDelete"
      />
    </v-dialog>
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex'
  import StockEditor
    from "./StockEditor"
  import CreateStockDialog
    from "./CreateStockDialog"
  import ConfirmDeleteStockDialog
    from "./ConfirmDeleteStockDialog"
  import StockSearch
    from "./StockSearch"
  import Toolbar
    from "@/components/common/Toolbar"

  export default {
    name: "Stocks",
    components: {
      Toolbar, StockSearch, ConfirmDeleteStockDialog, CreateStockDialog, StockEditor},
    data() {
      return {
        search: '',

        newSelectedStock: null,
        selectedStock: null,

        createStockDialog: false,
        confirmDeleteStockDialog: false,

        beforeFirstSearch: true,
      }
    },
    watch: {
      selectedStock(newValue) {
        this.newSelectedStock = (newValue == null) ? null : this.cloneObject(newValue)
      },
    },
    methods: {
      ...mapActions(['addStock', 'activateStock', 'deleteStock']),

      cloneObject(obj) {
        return JSON.parse(JSON.stringify(obj))
      },
      resetChanges() {
        this.selectedStock = this.cloneObject(this.selectedStock)
      },

      onStockSelection(stock) {
        this.selectedStock = stock
        this.$nextTick(() => {
          if (stock != null) this.$refs['editor'].onRefresh(stock.dq)
        })
      },
      getSearchResults(prevDQ) {
        this.$refs['search'].getSearchResults(prevDQ)
      },

      onStockInserted({name, dq}) {
        this.search = name
        this.$nextTick(() => this.getSearchResults(dq))
      },
      updateStock() {
        if (!this.newSelectedStock.ids.isin.match(/^[A-Z]{2}[A-Z0-9]{9}[0-9]$/) || !this.newSelectedStock.ids.wkn.match(/^[A-Z0-9]{6}$/)
          || !this.newSelectedStock.name) {
          this.$store.commit('SHOW_SNACKBAR', {text: 'Invalid inputs! Make sure Name, ISIN and WKN are valid.'})
          return
        }

        this.addStock(this.newSelectedStock).then(() => {
          this.activateStock(this.newSelectedStock.dq).then(() => {
            this.$store.commit('SHOW_SNACKBAR', {text: `Succesfully updated stock with DQ ${this.newSelectedStock.dq}`})
            this.getSearchResults(this.newSelectedStock.dq)
          })
        }).catch(() => {
          this.$store.commit('SHOW_SNACKBAR', {text: 'Failed to update product. Please try again later!'})
        })
      },
      confirmDelete() {
        this.deleteStock(this.selectedStock.dq).then(() => {
          this.$store.commit('SHOW_SNACKBAR', {text: `Succesfully deleted stock with DQ ${this.selectedStock.dq}!`})
          this.getSearchResults()
        }).catch(() => {
          this.$store.commit('SHOW_SNACKBAR', {text: `Failed to delete stock with DQ ${this.selectedStock.dq}!`})
        })

        this.confirmDeleteStockDialog = false
      },

      reactivate() {
        let dq = this.selectedStock.dq
        this.activateStock(dq).then(response => {
          this.$store.commit('SHOW_SNACKBAR', {text: `Succesfully re-derived stock with DQ ${dq}`})
          this.$set(this, 'selectedStock', response.data.data)
        })
      },
    },
  }
</script>

