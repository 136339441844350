<template>
  <v-container>
    <v-row>
      <v-col
        v-if="!isInsert"
        cols="12"
      >
        <div v-if="newValue.meta.active === 1">
          <v-icon
            left
            color="success"
          >
            check_circle_outline
          </v-icon>
          <strong class="success--text">{{ $t('stocks.productIsActive') }}</strong>
        </div>
        <div v-else-if="newValue.meta.active === 0">
          <v-icon
            left
            color="warning"
          >
            help_outline
          </v-icon>
          <strong class="warning--text">{{ $t('stocks.productStatusUnknown') }}</strong>
        </div>
        <div v-else>
          <v-icon
            left
            color="error"
          >
            error_outline
          </v-icon>
          <strong
            class="error--text"
          >{{ $t('stocks.productIsInactive') }} {{ newValue.meta.derivation.error.join() }}</strong>
        </div>
      </v-col>
      <v-col
        cols="4"
      >
        <v-text-field
          v-model="newValue.dq"
          label="DQ"
          readonly
        />
      </v-col>
      <v-col
        cols="8"
      >
        <v-text-field
          v-model="newValue.name"
          :rules="nameRules"
          label="Name"
          @input="emitChange"
        />
      </v-col>
    </v-row>
    <id-fields
      ref="editIds"
      v-model="newValue.ids"
      :is-insert="isInsert"
      @change="emitChange"
    />
    <v-row>
      <v-col
        cols="6"
      >
        <v-autocomplete
          v-model="newValue.common.country"
          :items="countryCodes"
          :search-input.sync="countryFieldSearch"
          :filter="countryCodeFilter"
          item-text="code"
          item-value="code"
          :label="$t('country')"
          @input="emitChange"
        >
          <template v-slot:item="{ item }">
            {{ item.name }} ({{ item.code }})
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="6"
      >
        <v-autocomplete
          v-model="newValue.common.currency"
          :items="currencyCodes"
          :filter="currencyCodeFilter"
          :search-input.sync="currencyFieldSearch"
          item-text="code"
          item-value="code"
          :label="$t('currency')"
          @input="emitChange"
        >
          <template v-slot:item="{ item }">
            {{ item.name }} ({{ item.code }})
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="6"
      >
        <v-autocomplete
          v-model="newValue.common.industryField"
          :search-input.sync="industryFieldSearch"
          :items="stock.industryFields"
          :filter="industryFieldFilter"
          item-value="dq"
          item-text="name"
          :label="$t('stocks.industryField')"
          @input="emitChange"
        >
          <template v-slot:item="{ item }">
            {{ item.name }} ({{ item.dq }})
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="6"
      >
        <v-dialog
          ref="dialog"
          v-model="expirationDateDialog"
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="newValue.common.expirationDate"
              :label="$t('stocks.expirationDate')"
              :append-icon="newValue.common.expirationDate ? 'clear' : null"
              v-bind="attrs"
              v-on="on"
              @click:append="clearDate"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="newValue.common.expirationDate"
            scrollable
            :allowed-dates="allowedExpirationDates"
            @change="closeDatePicker"
          >
          </v-date-picker>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row
      row
      wrap
      class="mt-7"
    >
      <v-col
        v-if="!isInsert"
        cols="12"
      >
        <v-chip
          v-for="(item, key) in newValue.derived.performanceIndices"
          :key="key"
          color="blue"
          outlined
        >
          {{ item }}
        </v-chip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import langEn from 'i18n-iso-countries/langs/en.json'
  import countries from 'i18n-iso-countries'
  import currencies from 'currency-codes'

  import { mapState } from 'vuex'
  import IdFields from "../common/IdFields"

  export default {
    name: "StockEditor",
    components: {IdFields},
    props: {
      isInsert: {
        type: Boolean,
        required: true,
      },
      value: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        nameRules: [
          v => !!v || this.$t('validation.notEmptyX', ['Name']),
        ],
        expirationDateDialog: false,
        industryFieldSearch: null,
        countryCodes: [],
        countryFieldSearch: null,
        currencyCodes: [],
        currencyFieldSearch: null,

        currentDQ: null,
      }
    },
    computed: {
      ...mapState(['stock']),
      newValue: {
        get() {
          let clone = this.cloneObject(this.value)
          if (!isNaN(Date.parse(clone['common'].expirationDate))) {
            clone['common'].expirationDate = clone['common'].expirationDate.substr(0, 10)
          }
          return clone
        },
        set(val) {
          this.$emit('input', val)
        },
      },
    },
    watch: {
      'newValue.ids.isin'(newVal) {
        this.newValue.dq = `QP00${newVal}`
      },
      selectedIndustryField(newValue) {
        this.newValue.common.industryField = newValue.substr(newValue.indexOf('(') + 1, 6)
      },
    },
    created() {
      let alpha3Codes = countries.getAlpha3Codes()
      this.countryCodes = Object
        .keys(alpha3Codes)
        .map(alpha3 => {
          let names = langEn.countries[alpha3Codes[alpha3]]
          return {code: alpha3, name: names instanceof Array ? names.join('/') : names}
        })

      this.currencyCodes = currencies
        .codes()
        .map(code => {
          return {code: code, name: currencies.code(code).currency}
        })
    },
    methods: {
      allowedExpirationDates(val) {
        return Date.parse(val) >= new Date()
      },
      clearDate() {
        this.newValue.common.expirationDate = null
        this.emitChange()
        this.$forceUpdate()
      },
      cloneObject(obj) {
        return JSON.parse(JSON.stringify(obj))
      },
      closeDatePicker() {
        this.expirationDateDialog = false
        this.$refs.dialog.save(this.newValue.common.expirationDate)
        this.emitChange()
      },
      countryCodeFilter(item) {
        return this.countryFieldSearch == null
          || item.code.toUpperCase().includes(this.countryFieldSearch.toUpperCase())
          || item.name.toUpperCase().includes(this.countryFieldSearch.toUpperCase())
      },
      currencyCodeFilter(item) {
        return this.currencyFieldSearch == null
          || item.code.toUpperCase().includes(this.currencyFieldSearch.toUpperCase())
          || item.name.toUpperCase().includes(this.currencyFieldSearch.toUpperCase())
      },
      emitChange() {
        this.$emit('input', this.newValue)
      },
      industryFieldFilter(item) {
        return this.industryFieldSearch == null
          || item.dq.toUpperCase().includes(this.industryFieldSearch.toUpperCase())
          || item.name.toUpperCase().includes(this.industryFieldSearch.toUpperCase())
      },
      onRefresh(dq) {
        if (this.currentDQ !== dq) {
          this.currentDQ = dq
          this.$refs['editIds'].onRefresh()
        }
      },
    },
  }
</script>

<style scoped>
</style>
